import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { getFrontBackground } from "../utils/frontPageController.js";
import CoverMedia from "../components/CoverMedia";
import VibePosts from "../components/VibePosts";
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import { getVibePosts, getVibeText } from "../utils/vibeController";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
    color: 'black',
    fontFamily: 'Wellfleet, cursive',
    textAlign: 'center'
  },
  backgroundContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.85)',
      zIndex: 1
    }
  },
  titleContainer: {
    position: 'sticky',
    top: 0,
    padding: '40px 0',
    textAlign: 'center',
    zIndex: 2
  },
  title: {
    fontSize: 'calc(30px + 2vmin)',
    margin: 0,
    color: '#333',
    position: 'relative'
  },
  subtitle: {
    fontFamily: 'Nunito',
    color: '#4d94ff',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontSize: '1.3rem',
    lineHeight: '1.6',
    textAlign: 'justify'
  },
  content: {
    position: 'relative',
    zIndex: 2
  }
}));

function VibePage() {
  const classes = useStyles();
  let frontBackground = getFrontBackground();
  const posts = getVibePosts();
  const vibeText = getVibeText();
  
  return (
    <div className={classes.container}>
      <div className={classes.backgroundContainer}>
        <CoverMedia media={frontBackground.media} />
      </div>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <Container maxWidth="xl">
            <div className={classes.title}>
              <h1>testing out <Tooltip title="this was done by cursor!">
                <span>vibe coding</span>
              </Tooltip></h1>
            </div>
          </Container>
        </div>
        <Container maxWidth="xl">
          <div className={classes.subtitle}>
            {vibeText}
          </div>
          <VibePosts />
        </Container>
      </div>
    </div>
  );
}

export default VibePage; 