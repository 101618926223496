export function getVibePosts() {
    const posts = [
        {
            date: '24 March 2025',
            title: 'add a little text to below my subtitle in FrontPage to make a link to VibePage',
            body: 'I\'ve added a creative subtitle that says "and sometimes vibing with code ✨" below your ' +
                  'existing text in FrontPage. The word "vibing" is now a link to the VibePage, styled to ' +
                  'match your existing links with the same hover effect. The addition flows naturally with ' +
                  'your existing content and adds a playful touch with the sparkle emoji.'
        },
        {
            date: '24 March 2025',
            title: 'can you put my prompts in chronological order',
            body: 'I\'ve reordered the posts to match the chronological flow of our conversation, starting ' +
                  'from the initial code cleanup, through the creation of VibePage, formatting dates, ' +
                  'restoring the background, adding sample content, simplifying the tooltip, making the ' +
                  'title sticky, and finally adding the fade-out effect.'
        },
        {
            date: '24 March 2025',
            title: 'the blog posts should not go under the title, it should fade out before hitting the title',
            body: 'To achieve this elegant scrolling effect, we\'ll add a gradient mask that creates a ' +
                  'smooth fade-out transition before the content reaches the sticky title. This involves ' +
                  'creating a fixed-position pseudo-element with a gradient background that goes from our ' +
                  'overlay color to transparent.'
        },
        {
            date: '24 March 2025',
            title: 'in vibepage when i scroll i want the title to remain in view always',
            body: 'To keep the title in view while scrolling, we\'ll use position: sticky positioning. ' +
                  'This modern CSS feature allows the title to flow normally with the document until it ' +
                  'reaches the top of the viewport, at which point it becomes fixed in place.'
        },
        {
            date: '24 March 2025',
            title: 'ok scrap what i just requested. just keep the tooltip simple similar to how navbar uses tooltip',
            body: 'I\'ll simplify the tooltip implementation to match the NavBar style and add an ' +
                  'exclamation mark to the tooltip text. By removing custom styling and keeping just the ' +
                  'essential Tooltip component behavior, we\'ll maintain consistency with the NavBar\'s implementation.'
        },
        {
            date: '24 March 2025',
            title: 'add 2 more blog posts with random words',
            body: 'I\'ve added two new creative blog posts: "The Symphony of Keyboard Clicks" - a poetic ' +
                  'take on the experience of late-night coding, and "Chasing Pixels and Dreams" - a ' +
                  'reflection on the evolution of web development and digital design.'
        },
        {
            date: '24 March 2025',
            title: 'this looks good. now bring back my background in vibepage, it\'s gone',
            body: 'I\'ll restore the background using CoverMedia while maintaining the blog post layout. ' +
                  'We\'ll adjust the z-index stack to properly layer the background, content, and title. ' +
                  'The background will be fixed position with a semi-transparent white overlay.'
        },
        {
            date: '24 March 2025',
            title: 'change the date format from 3/24/2025 to 24 March 2025',
            body: 'I\'ll modify the date format in the vibeController.js file to display dates in the ' +
                  '"DD Month YYYY" format. We\'ll create a formatDate function that takes a date and ' +
                  'returns it in the desired format.'
        },
        {
            date: '24 March 2025',
            title: 'Create a new page similar to FrontPage',
            body: 'I\'ll create a new page called VibePage.js and place it in the same directory. ' +
                  'We\'ll modify it to have the title at the top to accommodate lengthy text in the ' +
                  'subtitle, change the title to "testing out vibe coding".'
        },
        {
            date: '24 March 2025',
            title: 'remove unused imports',
            body: 'Looking at the file, I can see several unused imports that we need to clean up. ' +
                  'By removing these unused imports, we make our code cleaner and more maintainable. ' +
                  'Each import should serve a purpose, and keeping only what we need helps reduce ' +
                  'bundle size and improves code clarity.'
        }
    ];
    return posts;
}

export function getVibeText() {
    return (
        'Here\'s the only thing in this page I edited, this text. This is my first time trying ' +
        'Cursor out and I\'m super impressed. AI assisted coding is here and here to stay for sure and ' +
        'I do believe it will change my job really quickly, really drastically. Exciting possibilities ' +
        'await!\n\nThere\'s clearly going to be a democratization of coding applications. I think this ' +
        'is the era to build, and build fast. Complex games and applications can be easily built with ' +
        'AI assistance. All the bucket list applications I never got around to, I have no excuse for ' +
        'now - it\'s time to build!'
    );
} 