import header_image from "../assets/station.jpg";
import header_video from "../assets/front2.mp4";

export function getFrontBackground(){
	return {
        media: `url(${header_image})`,
        //media: `${header_video}`,
        title: ""
    }
}

export function getCurrentLocation(){
	return "New York"
}