import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import WorkExperiences from '../components/WorkExperiences.js';
import Container from '@material-ui/core/Container';
import header_image from "../assets/alaska_cropped.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
    color: 'black',
    fontFamily: 'Wellfleet, cursive',
    textAlign: 'center'
  },
  backgroundContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.85)',
      zIndex: 1
    }
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(75%)'
  },
  titleContainer: {
    padding: '40px 0',
    marginBottom: '20px'
  },
  title: {
    fontSize: 'calc(30px + 2vmin)',
    margin: 0,
    color: '#333',
    position: 'relative'
  },
  content: {
    position: 'relative',
    zIndex: 2
  },
  experiencesContainer: {
    position: 'relative'
  }
}));

function WorksPage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.backgroundContainer}>
        <img className={classes.backgroundImage} src={header_image} alt="Background" />
      </div>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <Container maxWidth="xl">
            <div className={classes.title}>
              <h1>Since graduation...</h1>
            </div>
          </Container>
        </div>
        <div className={classes.experiencesContainer}>
          <Container maxWidth="xl">
            <WorkExperiences />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default WorksPage;