import React from "react";

import thryft_image from "../assets/thryft_logo.JPG";
import brevity_image from "../assets/brevity.JPG";
import telegram_image from "../assets/teleg.png";
import jigsaw_image from "../assets/jigsaw.jpg";
import capsol_image from "../assets/capsol_logo.PNG";



import dso_logo from "../assets/company_logos/dso_logo.png";
import paypal_logo from "../assets/company_logos/paypal_logo.png";
import facebook_logo from "../assets/company_logos/facebook_logo.png";

import brevity_logo from "../assets/project_logos/brevity.jpg";
import kirby_logo from "../assets/project_logos/kirby_game.JPG";
import starter_logo from "../assets/project_logos/starter_kit.jpg";


// meta
import spark_image from "../assets/meta/spark.png";
import quest3_image from "../assets/meta/quest3.jpg";
import orion_image from "../assets/meta/Orion_Family.jpg";
import rbm_image from "../assets/meta/rbm.jpg";

export function getProjects(){
	return [
      {
        //media: `url(${header_image})`,
        media: `${brevity_image}`,
        title: "Brevity",
        description: "Application for productivity. In progress."
      },
      /*
      {
        //media: `url(${header_image})`,
        media: `${thryft_image}`,
        title: "Thryft",
        description: "Worked on Thryft's book deposit system for some time."
      },
      */
      {
        //media: `url(${header_image})`,
        media: `${telegram_image}`,
        title: "ChambersBot",
        description: "Telegram bot to set reminders."
      },
      {
        //media: `url(${header_image})`,
        media: `${capsol_image}`,
        title: "Capsol",
        description: "Smart pillbox with mobile app integration. Submitted to IDEAHACKS 2020."
      },
      {
        //media: `url(${header_image})`,
        media: `${jigsaw_image}`,
        title: "Jigsaw",
        description: "Skill-sharing platform. Submitted to AngelHack Global Hackathon."
      }
    ];
}
// TODO
export function getProjects_list(){
  return [
    {
      media: `${starter_logo}`,
      title: "Django-React Starter Kit",
      link: "https://github.com/dailybruin/starter.django-react",
      description: (
        <>
          <p>
            Developed this during my work as a software engineer with the <a href="https://dailybruin.com">Daily Bruin</a>. This starter kit allows for the rapid development of internal tools by handling most of the boilerplate in terms of routing.
          </p>
          <p>
            It's essentially a Docker container which implements NGINX infrastructure to host both a React Frontend and a Django Backend from a single endpoint.
          </p>
        </>
      )
    },
    {
      media: `${kirby_logo}`,
      title: "Kirby's Christmas Dinner",
      link: "",
      description: (
        "Move around on a slippery winter wonderland, avoiding pellets from the aerial Kirby! Developed as part of UCLA's CS174A (Introduction to Computer Graphics)."
      )
    },
    {
      media: `${brevity_logo}`,
      title: "Brevity",
      link: "",
      description: (
        "Enhance your productivity with timers for every need. Work in progress."
      )
    },
  ]
}

export function getWorkExperiences(){
  return [
    {
      media: `${spark_image}`,
      title: "Meta Spark AR",
      description: (
        <ul>
          <li>
            Model-in-effect: Enabled different AR effects to use Computer Vision models, 
            enabling quick iteration for research teams for AR effects.
          </li> 
          <li>
            Meta Spark was discontinued in 2024.
          </li>
        </ul>
      )
    },
    {
      media: `${quest3_image}`,
      title: "Meta Quest 3",
      description: (
        <ul>
          <li>
            Mixed Reality mode: Developed World Understanding APIs on top of XR APIs for Mixed Reality Applications.
          </li>
        </ul>
      )
    },
    {
      media: `${orion_image}`,
      title: "Orion",
      description: (
        <ul>
          <li>
            Developed fiducial tracking on Orion.
          </li>
          <li>
            Follow mode: Developed methods to improve augment stability in follow mode. 
            Iterated on on-the-go display format with design and product partners.
          </li>
        </ul>
      )
    },
    {
      media: `${rbm_image}`,
      title: "Ray-Ban Meta Glasses",
      description: (
        <ul>
          <li>
            Work in progress :)
          </li>
        </ul>
      )
    },
  ]
}