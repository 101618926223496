import React from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { getVibePosts } from "../utils/vibeController.js";
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    'padding': 30
  },
  entry: {
    'margin': '30px 0',
    'padding': '20px',
    'background': 'rgba(255, 255, 255, 0.9)'
  },
  date: {
    color: '#666',
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: '#333'
  },
  body: {
    whiteSpace: 'pre-line',
    lineHeight: 1.6,
    color: '#444'
  },
  frame: {
    'paddingBottom': 15,
    'paddingTop': 15,
    'fontFamily': 'Nunito'
  }
}));

function VibePosts() {
  const classes = useStyles();
  const posts = getVibePosts();

  function PostEntry(post) {
    return (
      <Paper elevation={3} className={classes.entry}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.date}>
              {post.date}
            </Typography>
            <Typography variant="h4" className={classes.title}>
              {post.title}
            </Typography>
            <Typography variant="body1" className={classes.body}>
              {post.body}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const postsComponent = posts.map(PostEntry);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.frame}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          {postsComponent}
        </Grid>
      </div>
    </Container>
  );
}

export default VibePosts; 