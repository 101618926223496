import React from "react";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { getWorkExperiences } from "../utils/projectsController.js";

const useStyles = makeStyles((theme) => ({
  container: {
    'padding': 30
  },
  entry: {
    'margin': 30
  },
  imageContainer: {
    width: '250px',
    height: '250px',
    border: '4px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '10px'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  frame: {
    'text-align': 'left',
    'paddingBottom': 15,
    'paddingTop': 15,
    'font-family': 'Nunito'
  },
  cell_image: {
    width: '250px',
    height: '250px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  cell_text: {
    textAlign: 'left',
    verticalAlign: 'text-top', 
    '& ul li': {
      padding: 15
    }
  },
}));

function WorkExperiences() {
  const classes = useStyles();

  let workExperiences = getWorkExperiences();
  // Individual work entry
  function WorkEntry(entry) {
    return(
      <Grid container className={classes.entry}>
        <Grid item xs={12} sm={12} md={4} className={classes.cell_image}>
          <div className={classes.imageContainer}>
            <img className={classes.image} src={entry.media} alt={entry.title} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.cell_text}>
            <h1>{entry.title}</h1>
            <h2>{entry.position}</h2>
            {entry.description}
        </Grid>
      </Grid>
    )
  }

  let workExperiencesComponent = workExperiences.map(WorkEntry)
  return (
    <Container maxWidth="lg" className={classes.container}>
    <div className={classes.frame}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {workExperiencesComponent}
      </Grid>
    </div>
  </Container>
  );
}

export default WorkExperiences;